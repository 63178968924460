/*
* BASE DEPENDENCIES
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
.error-404__flair {
  font-size: 25vw;
  color: #002c45;
  font-weight: bold;
}

@media screen and (min-width: 1600px) {
  .error-404__flair {
    font-size: 320px;
  }
}

.error-404__section .input {
  border-color: #cccccc;
}
