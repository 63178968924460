
/*
* BASE DEPENDENCIES
*/

@import
	"libraries/ecco/ecco";

/*
* SHARED
*/

@import
	"shared/vars";

.error-404__flair {
	font-size: 25vw;
	color: $color-brand;
	font-weight: bold;

	@include min($max-width) {
		font-size: 320px;
	}
}

.error-404__section {
	.input {
		border-color: #cccccc;
	}
}